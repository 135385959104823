@import 'antd/dist/antd.less#7';


@font-family: "Manrope", "Helvetica Neue For Number", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;

//@primary-color: #0052CC;
//@primary-color: #434343;
@primary-color: #359DDC;

@layout-sider-background: @primary-color;
@layout-header-padding: 0px 0px;

.ant-breadcrumb {
  font-size: 20px;
}

#root{
  height: 100%;
}

.App{
  height: 100%;
}

.App-sider {
  bottom: 0px !important;
  margin-left: -10px !important;
}

.App-sider>.ant-layout-sider-trigger {
  margin-bottom: 40px;
  position: unset;
  background-color: @primary-7;
}

.Header-text {
  color: @text-color-dark !important;
  margin: 0 !important;
  line-height: unset !important;
  font-size: 24px !important;

}

.App-logo {
  height: 40px;
  pointer-events: none;
}

.App-header {
  color: @text-color-dark !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.App-body{
  min-height: calc(100% - 95px);
  padding: 0px 5px;
}

.App-footer {
  bottom: 0px;
  width: 100%;
  background-color: @layout-header-background !important;
  color: @text-color-dark !important;
  padding: 5px !important;
  text-align: center;
}



.site-card-wrapper{
  width: 100%;
  padding-top: 0px;
  padding-bottom: 10px;
}

.Appspace-card {
  border-radius: 20px !important;
  width: 300px;
  height: 300px;
  text-align: center;
}

.Appspace-card>.ant-card-head:hover{
  background-color: #f9f9f9;
  border-radius: 20px !important;
}

.Appspace-card>.ant-card-body {
  padding: 0;
  height: 65%;
}

.Appspace-card>.ant-card-actions {
  border-radius: 20px !important;
}

.App-list-item-link>li{
  padding-left: 20px;
  padding-right: 20px;
}


a.App-list-item-link>li:hover {
  background-color: #f9f9f9;
}

.App-list-more-link>li{
  padding-left: 20px;
  padding-right: 20px; 
}

.General-card {
  border-radius: 20px !important;
  width: 300px;
  height: 300px;
  box-shadow: 0px 10px 10px 2px #888888;
}
.General-card.active{
  border: solid !important;
}
.General-card>.ant-card-actions {
  border-radius: 20px !important;
}

.General-card.ant-card-hoverable {
  box-shadow: 0px 1px 1px 0px #888888;
}

.General-card-head-center>.ant-card-head{
  text-align: center
}

.ant-form-item-label>label {
  font-weight: bold;
}

.ant-card-body{
  overflow: hidden
}

* {
  word-wrap: break-word
}

.GeneralTable-actions {
  visibility: hidden
}

.ant-table-cell-row-hover>.GeneralTable-actions {
  visibility: unset
}


.Small-action-card {
  height: auto;
  width: auto;
  text-align: center;
  font-size: 30px !important;
  min-width: 1em;
  max-height: 4em;
  max-width: 10em;
  box-shadow: 0px 5px 5px 2px #888888;
}
.Small-action-card>.ant-card-body {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
}
.App-list-item-link>.ant-list-item>.ant-typography {
  margin-bottom: 0em;
}

.App-section-menu{
  flex: 0 0 0%;
  min-height: calc(100vh - 125px);
}
.App-section-content{
  flex: 24 0 0%;
  min-height: calc(100vh - 125px);
}

.App-section-menu>.ant-menu {
  width: 180px;
  height: 100%;
}
.App-section-content>.ant-card>{
  height: 100%;
}
.App-section-menu.App-section-menu-collapsed>.ant-menu {
  width: 40px !important;
}

.App-section-menu>.ant-menu {
  width: 180px !important;
  height: 100%;
}

.App-section-content{
  max-width: calc(100vw - 190px);
}
.App-section-content.App-section-content-collapsed-menu{
  max-width: calc(100vw - 50px);
}

.App-section-content>.ant-card>.ant-card-body {
  padding-bottom: 5px;
}

.ant-menu-title-content {
  font-weight: bolder
}

.ant-breadcrumb-link {
  font-weight: bold
}

.ant-popover-inner {
  border-radius: 12px !important;
  box-shadow: 0px 8px 8px 1px #888888 !important;
}

.ant-popover-title {
  text-align: center;
  font-weight: bolder !important;
  font-size: 18px;
}
